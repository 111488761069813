import { styled } from "@mui/material"

export const LayoutWrapper = styled("div")({
  position: "relative",
  display: "flex",
  maxWidth: "100vw",
  minHeight: "100vh"
})

export const Base = styled("div")(({ expand, remarks }) => {
  let styles = {
    position: "relative",
    height: "100vh",
    maxHeight: "100vh",
    width: "100%"
  }
  // sidebar epand width is 212px, unexpend is 77
  let total = expand ? 212 : 77
  // remark epand width is 350px
  if (remarks) {
    total += 350
  }
  let minWidth = `calc( 100% - ${total}px )`
  styles.minWidth = minWidth
  return styles
})

export const Content = styled("div")(({ theme }) => ({
  position: "relative",
  height: "calc( 100vh - 70px - 100px)",
  maxHeight: "calc( 100vh - 70px - 100px)",
  overflow: "auto",
  padding: "16px 0",
  backgroundColor: theme.palette.background.paper
}))
