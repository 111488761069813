import React from "react"
import Head from "next/head"
// Components
import SideBar from "@components/sideBar"
import { LayoutWrapper } from "@hoc/create-main-layout.styles"
// Styles
import "../styles/styles.css"
// Libs
import "@utils/i18n"
import moment from "moment"
import theme from "../utils/theme"
import getConfig from "next/config"
import { wrapper } from "../states/store"
import { CacheProvider } from "@emotion/react"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import createEmotionCache from "@styles/createEmotionCache"
import assetPrefix from "@prefix"
import isBrowser from "@utils/isBrowser"
import apiBaseUrl from "@utils/apiBaseUrl"
import { errorCodeCheck } from "../states/apis/apiServices"
import { setMe } from "@slices/admins"
import { getSideBarByPath, getCookieToken } from "@constants"

// moment.updateLocale("zh-hk", { week: { dow: 1 } })
moment.updateLocale("zh-hk")

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps, showSideBar }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=5"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" type="image/x-icon" href={`${assetPrefix}/images/favicon.ico`} />
        {process.env.APP_ENV === "uat" && (
          <script
            type="text/javascript"
            src="https://redso-elastic.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-onpk8x/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=f097e0a0"
          ></script>
        )}
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <LayoutWrapper>
            {showSideBar && <SideBar />}
            <Component {...pageProps} />
          </LayoutWrapper>
        </ThemeProvider>
      </CacheProvider>
    </>
  )
}

MyApp.getInitialProps = wrapper.getInitialPageProps(({ dispatch }) => async ({ Component, ctx }) => {
  console.log("App getInitialProps", { ctx, Component })

  const { pathname, req, res } = ctx

  if (!isBrowser) {
    try {
      let token = getCookieToken(req?.headers?.cookie)

      if (token) {
        const resMe = await fetch(`${apiBaseUrl}/admins/me`, {
          method: "GET",
          headers: new Headers({ "content-type": "application/json", Authorization: `Bearer ${token}` })
        })
        if (errorCodeCheck(resMe.status)) return

        const json = await resMe.json()
        dispatch(setMe(json))

        if (pathname === "/login") {
          res.writeHead(307, { Location: "/cms/" })
          res.end()
        } else if (pathname && pathname !== "/" && pathname !== "/no-access-right") {
          let { listItem } = getSideBarByPath(pathname)
          if (listItem && !json.type.permissions.includes(listItem.id)) {
            res.writeHead(307, { Location: "/cms/no-access-right" })
            res.end()
          }
        }
      } else if (pathname !== "/login" && pathname !== "/resetPassword") {
        res.writeHead(307, { Location: "/cms/login" })
        res.end()
      }
    } catch (error) {
      console.log("Server token setting error - ", error)
    }
  }

  const { publicRuntimeConfig: config } = getConfig()
  let pageProps = {}
  let showSideBar = Component.showSideBar || false

  try {
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx, config })
    }
  } catch (err) {
    console.log("The Error happened in ", isBrowser ? "Client" : "Server")
  } finally {
    return { pageProps, config, showSideBar }
  }
})

export default wrapper.withRedux(MyApp)
