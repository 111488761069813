import { createTheme } from "@mui/material/styles"
// https://mui.com/customization/default-theme/#explore

const colors = {
  blue: "#352f7c",
  blue_light: "#2f80ed",

  purple: "#796a9a",

  gray_opacity: "rgba(191, 194, 215, 0.38)",
  gray: "#cfcfd9",
  gray_1: "#f2f2f2",
  gray_2: "#f6f6f6",
  gray_3: "#bcc1dc",
  gray_4: "#fefefe",
  gray_5: "#c4c4c4",
  gray_6: "#f7f8fa",
  gray_7: "#e0e0e0",
  gray_8: "#fffcf2",
  gray_9: "#666666",
  gray_10: "#ebebeb",
  gray_11: "#B2B2C2",
  gray_12: "#ebeef2",
  gray_13: "#BDBDBD",
  gray_14: "#E2E2E2",

  brown: "#9c9999",
  brown_1: "#494766",
  brown_2: "#333333",

  yellow_opacity: "rgba(250, 192, 67, 0.2)",
  yellow: "#fac043",
  yellow_1: "#FFF2CF",
  yellow_2: "#F5943A",
  yellow_light: "#fdefc2",
  yellow_dark: "#B68720",

  green: "#0a792d",
  green_light: "#34ae60",
  green_1: "#6fcf97",
  green_2: "#A2C851",

  red: "#f66361",
  red_1: "#F5503A",
  red_dark: "#eb5757",

  white: "#ffffff",

  paper: "#fcfcfc",

  black: "#2f2e41",

  transparent: "transparent"
}
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: colors.yellow,
      light: colors.yellow,
      dark: colors.yellow_dark,
      contrastText: colors.white
    },
    primary_dark: {
      main: colors.yellow,
      light: colors.yellow,
      dark: colors.yellow_dark,
      contrastText: colors.black
    },
    secondary: {
      main: colors.gray,
      light: colors.gray,
      dark: colors.gray,
      contrastText: colors.white
    },
    secondary_black: {
      main: colors.gray,
      light: colors.gray,
      dark: colors.gray,
      contrastText: colors.black
    },
    error: {
      main: colors.red,
      light: colors.red_light,
      dark: colors.red_dark
    },
    info: {
      main: colors.blue,
      light: colors.blue_light
    },
    success: {
      main: colors.green,
      dark: colors.green,
      light: colors.green_light
    },
    success_light: {
      main: colors.green_1,
      dark: colors.green,
      light: colors.green_light,
      contrastText: colors.white
    },
    paper_brown_1: {
      main: colors.paper,
      light: colors.white,
      dark: colors.gray_10,
      contrastText: colors.brown_1
    },
    gray_3_black: {
      main: colors.gray_3,
      contrastText: colors.black
    },
    gray_black: {
      main: colors.gray,
      light: colors.gray_11,
      dark: colors.brown,
      contrastText: colors.black
    },
    gray_white: {
      main: colors.gray,
      dark: colors.brown,
      contrastText: colors.white
    },
    text: {
      red: colors.red,
      red_dark: colors.red_dark,
      green: colors.green,
      primary: colors.black,
      secondary: colors.brown,
      yellow: colors.yellow,
      white: colors.white,
      gray: colors.gray,
      gray_3: colors.gray_3,
      gray_8: colors.gray_8,
      gray_9: colors.gray_9,
      brown: colors.brown,
      brown_1: colors.brown_1,
      blue_light: colors.blue_light,
      brown_2: colors.brown_2
    },
    divider: colors.yellow,
    background: {
      default: colors.white,
      paper: colors.paper,
      blue: colors.blue,
      purple: colors.purple,
      dark: colors.black,
      red_1: colors.red_1,
      gray_opacity: colors.gray_opacity,
      gray: colors.gray,
      gray_2: colors.gray_2,
      gray_4: colors.gray_4,
      gray_6: colors.gray_6,
      gray_7: colors.gray_7,
      gray_8: colors.gray_8,
      gray_10: colors.gray_10,
      gray_12: colors.gray_12,
      gray_13: colors.gray_13,
      gray_14: colors.gray_14,
      green: colors.green,
      green_2: colors.green_2,
      yellow: colors.yellow,
      yellow_1: colors.yellow_1,
      yellow_2: colors.yellow_2,
      yellow_light: colors.yellow_light,
      yellow_opacity: colors.yellow_opacity
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  },
  zIndex: {
    header: 1000,
    footer: 1000,
    statusBar: 1000,
    sideBar: 1200,
    modal: 1300
  },
  border: {
    black: colors.black,
    red: colors.red,
    brown: colors.brown,
    brown_1: colors.brown_1,
    gray: colors.gray,
    gray_3: colors.gray_3,
    gray_7: colors.gray_7,
    gray_10: colors.gray_10,
    yellow: colors.yellow,
    transparent: colors.transparent
  },
  tabs: {
    primary: {
      text: colors.brown,
      contrastText: colors.yellow,
      background: colors.white,
      contrastBackground: colors.white,
      borderColor: colors.yellow,
      borderBottomColor: colors.white
    },
    secondary: {
      text: colors.brown,
      contrastText: colors.white,
      background: colors.gray_1,
      contrastBackground: colors.gray,
      borderColor: colors.transparent,
      borderBottomColor: colors.transparent
    }
  },
  customDivider: {
    yellow: colors.yellow,
    gray: colors.gray,
    gray_3: colors.gray_3,
    black: colors.black
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid",
          borderColor: colors.gray_3,
          borderTop: "none",
          borderRadius: "0 0 2px 2px",
          backgroundColor: colors.white,
          boxSizing: "content-box"
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingBottom: "5px",
          marginTop: "-10px"
        }
      }
    }
  }
})

export default theme
