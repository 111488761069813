import { makeStyles, useTheme } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    padding: "10px",
    width: "100%",
    height: "auto",
    border: (props) => `1px solid ${props.theme.palette.primary.main}`,
    borderRadius: "10px",
    marginTop: "10px"
  }
})

export const ItemWrapper = (props) => {
  const theme = useTheme()
  const classes = useStyles({ ...props, theme })
  return <div className={classes.root}>{props.children}</div>
}
