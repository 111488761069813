import { useEffect, Fragment, memo } from "react"
import Router, { useRouter } from "next/router"
import { useSelector, useDispatch } from "react-redux"
// Components
import { Row, VSpace } from "@layout"
import { ImgBase } from "@components/images"
import SideItem from "./side-item"
import { LayoutWrapper } from "./index.styles"
import { setExpand, setChoosedItem, setChoosedCategory } from "../../states/slices/sidebar"
// Styles
// Libs
import { sideItemList, getSideBarByPath } from "@constants"
import { Me } from "@selector"
import { isEmpty } from "@utils/validator"

const CollapseIcon = memo(({ menuOpen }) => {
  const dispatch = useDispatch()
  return (
    <ImgBase
      srcx={menuOpen ? "fluent-window-header-vertical-20-filled@3x" : "fluent-window-new-16-filled@3x"}
      width="25px"
      height="25px"
      cursor="pointer"
      objectfit="cover"
      onClick={() => dispatch(setExpand(!menuOpen))}
    />
  )
})

const WebsiteIcon = memo(() => {
  return (
    <ImgBase
      src="new-logo-1-1"
      alt="website logo"
      width="79px"
      height="35px"
      cursor="pointer"
      objectfit="cover"
      onClick={() => Router.push({ pathname: "/" })}
    />
  )
})

const SideBar = () => {
  const me = useSelector(Me)

  const menuOpen = useSelector((state) => state.sidebar.expand)
  const choosedItem = useSelector((state) => state.sidebar.choosedItem)

  const dispatch = useDispatch()

  const { pathname } = useRouter()

  useEffect(() => {
    if (pathname && pathname !== "/" && pathname !== "/no-access-right") {
      let { sideItem, listItem } = getSideBarByPath(pathname)
      if (sideItem && listItem) {
        dispatch(setChoosedCategory(sideItem.id))
        dispatch(setChoosedItem(listItem.id))
      }
    } else if (pathname === "/" || pathname === "/no-access-right") {
      dispatch(setChoosedCategory(""))
      dispatch(setChoosedItem(""))
    }
  }, [pathname, me])

  useEffect(() => {
    if (
      pathname !== "/" &&
      pathname !== "/no-access-right" &&
      choosedItem &&
      me &&
      !me.type.permissions.includes(choosedItem)
    ) {
      Router.push({ pathname: "/no-access-right" })
    }
  }, [choosedItem, me])

  const formatList = (list) => {
    return me ? list.filter((item) => me.type.permissions.includes(item.id)) : []
  }

  return (
    <>
      <LayoutWrapper expand={Number(menuOpen)}>
        <Row sx={{ justifyContent: menuOpen ? "space-between" : "center" }}>
          <CollapseIcon menuOpen={menuOpen} />
          {menuOpen && <WebsiteIcon />}
        </Row>
        <VSpace length={20} />
        {sideItemList.map(
          (item, index) =>
            !isEmpty(formatList(item.list)) && (
              <Fragment key={`side item ${index}`}>
                <SideItem sideItem={item} menuOpen={menuOpen} permissions={me?.type.permissions} />
                <VSpace length={20} />
              </Fragment>
            )
        )}
      </LayoutWrapper>
    </>
  )
}

export default SideBar
