import { memo } from "react"
import { ImgBase } from "@components/images"
import { HSpace, Row, VSpace, Text } from "@layout"
import { ItemWrapper } from "./side-item.styles"
import { t } from "i18next"
import NextLink from "next/link"
import { Me } from "@selector"
import { useSelector, useDispatch } from "react-redux"
import { setExpand, setChoosedItem, setChoosedCategory } from "../../states/slices/sidebar"

const SideItemLink = ({
  index,
  name,
  link,
  id,
}) => {
  const dispatch = useDispatch()
  const choosedItem = useSelector((state) => state.sidebar.choosedItem)
  return (
    <>
      {!!index && <VSpace length={10} />}
      <NextLink href={link}>
        <Text
          width="100%"
          weight={500}
          cursor={choosedItem === id ? "default" : "pointer"}
          color={choosedItem === id ? "yellow" : "gray"}
          onClick={() => dispatch(setChoosedItem(id))}
        >
          {t(name)}
        </Text>
      </NextLink>
    </>
  )
}

const SideItem = ({
  sideItem,
  menuOpen,
}) => {
  const dispatch = useDispatch();

  const me = useSelector(Me)
  
  const choosedCategory = useSelector((state) => state.sidebar.choosedCategory)

  const isChoosedCategory = choosedCategory === sideItem.id;

  const list = me ? sideItem.list.filter((item) => me.type.permissions.includes(item.id)) : [];

  const handleCategoryClick = () => {
    dispatch(setExpand(true))
    dispatch(setChoosedCategory(sideItem.id))
  }

  return (
    <>
      {menuOpen ? (
        <>
          <Row
            sx={{ cursor: `${isChoosedCategory ? "default" : "pointer"}`, justifyContent: "space-between" }}
            onClick={handleCategoryClick}
          >
            <Row>
              <ImgBase
                src={isChoosedCategory ? sideItem.srcChoosed : sideItem.src}
                alt={sideItem.alt}
                width="30px"
                height="30px"
                objectfit="cover"
              />
              <HSpace length={10} />
              <Text weight={500} color={isChoosedCategory ? "yellow" : "gray"}>
                {t(sideItem.name)}
              </Text>
            </Row>
            <Row>
              <HSpace length={10} />
              <ImgBase src="bx-bxs-right-arrow" alt="right arrow" width="12px" height="12px" />
            </Row>
          </Row>
          {isChoosedCategory && (
            <ItemWrapper>
              {list.map((item, index) => (
                <SideItemLink
                  key={`sideList_item_${index}`}
                  index={index}
                  name={item.name}
                  link={item.link}
                  id={item.id}
                />
              ))}
            </ItemWrapper>
          )}
        </>
      ) : (
        <ImgBase
          src={isChoosedCategory ? sideItem.srcChoosed : sideItem.src}
          alt={sideItem.alt}
          width="30px"
          height="30px"
          objectfit="cover"
          cursor="pointer"
          onClick={handleCategoryClick}
        />
      )}
    </>
  )
}

export default memo(SideItem)
