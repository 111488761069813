import { styled } from "@mui/material"
import hideScroll from "@styles/hideScroll"

export const LayoutWrapper = styled("div")(({ theme, expand }) => ({
  padding: "20px",
  width: expand ? "212px" : "77px",
  minWidth: expand ? "212px" : "77px",
  height: "100vh",
  background: theme.palette.background.blue,
  zIndex: theme.zIndex.sideBar,
  boxShadow: "5px 0 10px -5px black",
  overflowY: "auto",
  ...hideScroll
}))
